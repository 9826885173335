import {
  CREATE_CAPACITY,
  UPDATE_CAPACITY,
  DELETE_CAPACITY,
  RETREIVE_CAPACITY,
} from 'src/actions/types'
const initialState = []

const CapacityReducer = (capacitys = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_CAPACITY:
      return [...capacitys, payload]
    case RETREIVE_CAPACITY:
      return payload
    case UPDATE_CAPACITY:
      return capacitys.map((capacity) => {
        if (capacity.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...capacity,
            ...payload,
          }
        } else {
          return capacity
        }
      })
    case DELETE_CAPACITY:
      return capacitys.filter(({ id }) => id !== payload.id)
    default:
      return capacitys
  }
}

export default CapacityReducer
