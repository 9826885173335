import { SET_ERROR } from 'src/actions/types'

const initialState = {}
export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_ERROR:
      return payload
    default:
      return state
  }
}
