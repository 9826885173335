import { RETREIVE_ASSET_STATUS } from 'src/actions/types'
const initialState = []

const AssetStatusReducer = (assetStatuses = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETREIVE_ASSET_STATUS:
      return payload
    default:
      return assetStatuses
  }
}

export default AssetStatusReducer
