import {
  ADD_GROUP_LOCATION,
  CREATE_LOCATION_GROUP,
  DELETE_GROUP_LOCATION,
  RETREIVE_LOCATION_GROUP,
  UPDATE_LOCATION_GROUP,
} from 'src/actions/types'
const initialState = []

const LocationGroupReducer = (locationgroup = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_LOCATION_GROUP:
      console.log(payload)
      return [...locationgroup, payload]
    case ADD_GROUP_LOCATION:
      console.log(payload)
      return [...locationgroup, payload]
    case RETREIVE_LOCATION_GROUP:
      return payload
    case UPDATE_LOCATION_GROUP:
      return locationgroup.map((lg) => {
        if (lg.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...lg,
            ...payload,
          }
        } else {
          return lg
        }
      })
    // case DELETE_ASSET:
    //   return assets.filter(({ id }) => id !== payload.id)
    case DELETE_GROUP_LOCATION:
      return locationgroup.filter(({ id }) => id !== payload.id)
    default:
      return locationgroup
  }
}

export default LocationGroupReducer
