import {
  ADD_GROUP_LOCATION,
  ADD_GROUP_USER,
  CREATE_OPERATOR_GROUP,
  DELETE_GROUP_LOCATION,
  RETREIVE_OPERATOR_GROUP,
  UPDATE_OPERATOR_GROUP,
} from 'src/actions/types'
const initialState = []

const OperatorGroupReducer = (operatorgroup = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_OPERATOR_GROUP:
      console.log(payload)
      return [...operatorgroup, payload]
    case ADD_GROUP_LOCATION:
      console.log(payload)
      return [...operatorgroup, payload]
    case ADD_GROUP_USER:
      console.log(payload)
      return [...operatorgroup, payload]
    case RETREIVE_OPERATOR_GROUP:
      return payload
    case UPDATE_OPERATOR_GROUP:
      return operatorgroup.map((og) => {
        if (og.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...og,
            ...payload,
          }
        } else {
          return og
        }
      })
    // case DELETE_ASSET:
    //   return assets.filter(({ id }) => id !== payload.id)
    case DELETE_GROUP_LOCATION:
      return operatorgroup.filter(({ id }) => id !== payload.id)
    default:
      return operatorgroup
  }
}

export default OperatorGroupReducer
