import {
  CREATE_MERK,
  UPDATE_MERK,
  DELETE_MERK,
  RETREIVE_MERKS,
  FILTERED_MERKS,
} from 'src/actions/types'
const initialState = []

const MerkReducer = (merks = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_MERK:
      console.log(payload)
      return [...merks, payload]
    case RETREIVE_MERKS:
      return payload
    case UPDATE_MERK:
      return merks.map((merk) => {
        if (merk.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...merk,
            ...payload,
          }
        } else {
          return merk
        }
      })
    case DELETE_MERK:
      return merks.filter(({ id }) => id !== payload.id)
    default:
      return merks
  }
}

export default MerkReducer
