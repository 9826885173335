import { combineReducers } from 'redux'
import auth from './auth'
import message from './message'
import merks from './merks'
import medias from './medias'
import capacitys from './capacitys'
import types from './types'
import templates from './templates'
import assetStatuses from './assetStatus'
import assetCategory from './assetCategory'
import assets from './assets'
import marks from './marks'
import units from './units'
import areas from './areas'
import locations from './locations'
import schedule from './schedules'
import bangunan from './Bangunan'
import operatorgroup from './operatorGroups'
import locationgroup from './locationGroups'
import transaction from './transactions'
import dashboard from './dashboard'
import search from './search'
import errors from './errors'
import userOperator from './userOperator'
import assetform from './assetForm'
import countAPAR from './countAPAR'
export default combineReducers({
  auth,
  message,
  merks,
  medias,
  capacitys,
  types,
  templates,
  assetStatuses,
  assets,
  marks,
  units,
  areas,
  locations,
  operatorgroup,
  locationgroup,
  schedule,
  transaction,
  bangunan,
  dashboard,
  search,
  assetCategory,
  errors,
  userOperator,
  assetform,
  countAPAR,
})
