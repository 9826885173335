import { CREATE_MEDIA, UPDATE_MEDIA, DELETE_MEDIA, RETREIVE_MEDIA } from 'src/actions/types'
const initialState = []

const MediaReducer = (medias = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_MEDIA:
      return [...medias, payload]
    case RETREIVE_MEDIA:
      return payload
    case UPDATE_MEDIA:
      return medias.map((media) => {
        if (media.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...media,
            ...payload,
          }
        } else {
          return media
        }
      })
    case DELETE_MEDIA:
      return medias.filter(({ id }) => id !== payload.id)
    default:
      return medias
  }
}

export default MediaReducer
