import { RETREIVE_COUNT_APAR } from 'src/actions/types'
const initialState = []

const CounAPAReducer = (countAPAR = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETREIVE_COUNT_APAR:
      return payload
    // case UPDATE_ASSET:
    //   return assets.map((asset) => {
    //     if (asset.id === payload.id) {
    //       // console.log(merk, payload)
    //       return {
    //         ...asset,
    //         ...payload,
    //       }
    //     } else {
    //       return asset
    //     }
    //   })
    // case DELETE_ASSET:
    //   return assets.filter(({ id }) => id !== payload.id)
    // case DELETE_IMAGE_ASSET:
    //   return assets.filter(({ id }) => id !== payload.id)
    default:
      return countAPAR
  }
}

export default CounAPAReducer
