import { RETREIVE_UNIT } from 'src/actions/types'
const initialState = []

const UnitReducer = (units = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETREIVE_UNIT:
      return payload
    default:
      return units
  }
}

export default UnitReducer
