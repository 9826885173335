import {
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  RETREIVE_TEMPLATE,
} from 'src/actions/types'
const initialState = []

const TemplateReducer = (templates = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_TEMPLATE:
      return [...templates, payload]
    case RETREIVE_TEMPLATE:
      return payload
    case UPDATE_TEMPLATE:
      return templates.map((template) => {
        if (template.id === payload.id) {
          return {
            ...template,
            ...payload,
          }
        } else {
          return template
        }
      })
    case DELETE_TEMPLATE:
      return templates.filter(({ id }) => id !== payload.id)
    default:
      return templates
  }
}

export default TemplateReducer
