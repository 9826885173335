import {
  CREATE_MARK,
  UPDATE_MARK,
  DELETE_MARK,
  RETREIVE_MARK,
  DELETE_IMAGE_MARK,
} from 'src/actions/types'
const initialState = null

const MarkReducer = (marks = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_MARK:
      return [...marks, payload]
    case RETREIVE_MARK:
      return payload
    case UPDATE_MARK:
      return marks.map((mark) => {
        if (mark.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...mark,
            ...payload,
          }
        } else {
          return mark
        }
      })
    case DELETE_MARK:
      return marks.filter(({ id }) => id !== payload.id)
    case DELETE_IMAGE_MARK:
      return marks.filter(({ id }) => id !== payload.id)
    default:
      return marks
  }
}

export default MarkReducer
