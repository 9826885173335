import { RETREIVE_LOCATION } from 'src/actions/types'
const initialState = []

const LocationReducer = (locations = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETREIVE_LOCATION:
      return payload
    default:
      return locations
  }
}

export default LocationReducer
