/* eslint-disable prettier/prettier */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const SET_MESSAGE = 'SET_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'

// Location
export const RETREIVE_UNIT = 'RETREIVE_UNIT'
export const RETREIVE_AREA = 'RETREIVE_AREA'
export const RETREIVE_LOCATION = 'RETREIVE_LOCATION'
export const RETREIVE_BANGUNAN = 'RETREIVE_BANGUNAN'

// Merk
export const CREATE_MERK = 'CREATE_MERK'
export const RETREIVE_MERKS = 'RETREIVE_MERKS'
export const UPDATE_MERK = 'UPDATE_MERK'
export const DELETE_MERK = 'DELETE_MERK'
export const FILTERED_MERKS = 'FILTERED_MERKS'

// Media
export const CREATE_MEDIA = 'CREATE_MEDIA'
export const RETREIVE_MEDIA = 'RETREIVE_MEDIA'
export const UPDATE_MEDIA = 'UPDATE_MEDIA'
export const DELETE_MEDIA = 'DELETE_MEDIA'

// Capacity
export const CREATE_CAPACITY = 'CREATE_CAPACITY'
export const RETREIVE_CAPACITY = 'RETREIVE_CAPACITY'
export const UPDATE_CAPACITY = 'UPDATE_CAPACITY'
export const DELETE_CAPACITY = 'DELETE_CAPACITY'

// Type
export const CREATE_TYPE = 'CREATE_TYPE'
export const RETREIVE_TYPE = 'RETREIVE_TYPE'
export const UPDATE_TYPE = 'UPDATE_TYPE'
export const DELETE_TYPE = 'DELETE_TYPE'

// Template
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE'
export const RETREIVE_TEMPLATE = 'RETREIVE_TEMPLATE'
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'

// Detail Template
export const CREATE_DETAIL_TEMPLATE = 'CREATE_DETAIL_TEMPLATE'
export const RETREIVE_DETAIL_TEMPLATE = 'RETREIVE_DETAIL_TEMPLATE'
export const UPDATE_DETAIL_TEMPLATE = 'UPDATE_DETAIL_TEMPLATE'
export const DELETE_DETAIL_TEMPLATE = 'DELETE_DETAIL_TEMPLATE'

// Mark
export const CREATE_MARK = 'CREATE_MARK'
export const RETREIVE_MARK = 'RETREIVE_MARK'
export const UPDATE_MARK = 'UPDATE_MARK'
export const DELETE_MARK = 'DELETE_MARK'
export const DELETE_IMAGE_MARK = 'DELETE_IMAGE_MARK'

// Asset
export const CREATE_ASSET = 'CREATE_ASSET'
export const RETREIVE_ASSET = 'RETREIVE_ASSET'
export const UPDATE_ASSET = 'UPDATE_ASSET'
export const DELETE_ASSET = 'DELETE_ASSET'
export const DELETE_IMAGE_ASSET = 'DELETE_IMAGE_ASSET'

// Transaction
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const RETREIVE_TRANSACTION = 'RETREIVE_TRANSACTION'

// Operator Group
export const CREATE_OPERATOR_GROUP = 'CREATE_OPERATOR_GROUP'
export const UPDATE_OPERATOR_GROUP = 'UPDATE_OPERATOR_GROUP'
export const ADD_GROUP_OPERATOR = 'ADD_GROUP_OPERATOR'
export const ADD_GROUP_USER = 'ADD_GROUP_USER'
export const RETREIVE_OPERATOR_GROUP = 'RETREIVE_OPERATOR_GROUP'
export const DELETE_GROUP_OPERATOR = 'DELETE_GROUP_OPERATOR'
export const DELETE_GROUP_OPERATOR1 = 'DELETE_GROUP_OPERATOR1'
export const DELETE_GROUP_OPERATOR_LOCATION = 'DELETE_GROUP_OPERATOR_LOCATION'

// Operator Group
export const CREATE_LOCATION_GROUP = 'CREATE_LOCATION_GROUP'
export const UPDATE_LOCATION_GROUP = 'UPDATE_LOCATION_GROUP'
export const ADD_GROUP_LOCATION = 'ADD_GROUP_LOCATION'
export const RETREIVE_LOCATION_GROUP = 'RETREIVE_LOCATION_GROUP'
export const DELETE_GROUP_LOCATION = 'DELETE_GROUP_LOCATION'

// Schedule
export const RETREIVE_SCHEDULE = 'RETREIVE_SCHEDULE'
export const RETREIVE_TEAM_SCHEDULE = 'RETREIVE_TEAM_SCHEDULE'
export const RETREIVE_OPERATOR_SCHEDULE = 'RETREIVE_OPERATOR_SCHEDULE'

// Asset Status
export const RETREIVE_ASSET_STATUS = 'RETREIVE_ASSET_STATUS'
export const ADD_ASSET_STATUS = 'ADD_ASSET_STATUS'
export const UPDATE_ASSET_STATUS = 'UPDATE_ASSET_STATUS'
export const DELETE_ASSET_STATUS = 'DELETE_ASSET_STATUS'

// Asset Form
export const RETREIVE_ASSET_FORM = 'RETREIVE_ASSET_FORM'
export const ADD_ASSET_FORM = 'ADD_ASSET_FORM'
export const UPDATE_ASSET_FORM = 'UPDATE_ASSET_FORM'
export const DELETE_ASSET_FORM = 'DELETE_ASSET_FORM'

export const RETREIVE_DASHBOARD = 'RETREIVE_DASHBOARD'

export const RETREIVE_COUNT_APAR = 'RETREIVE_COUNT_APAR'

export const RETREIVE_SEARCH = 'RETREIVE_SEARCH'

export const RETREIVE_ASSET_CATEGORY = 'RETREIVE_ASSET_CATEGORY'

export const RETREIVE_DETAIL_ASSET_CATEGORY = 'RETREIVE_DETAIL_ASSET_CATEGORY'

export const RETREIVE_USER_OPERATOR = 'RETREIVE_USER_OPERATOR'

export const SET_ERROR = 'SET_ERROR'

// Histori Maintenance
export const ADD_ASSET_HISTORI_MAINTENANCE = 'ADD_ASSET_HISTORI_MAINTENANCE'
export const RETREIVE_ASSET_HISTORI_MAINTENANCE = 'RETREIVE_ASSET_HISTORI_MAINTENANCE'
export const DELETE_IMAGE_HISTORI_MAINTENANCE = 'DELETE_IMAGE_HISTORI_MAINTENANCE'
export const UPDATE_HISTORI_MAINTENANCE = 'UPDATE_HISTORI_MAINTENANCE'

// Histori Usage
export const ADD_ASSET_HISTORI_USAGE = 'ADD_ASSET_HISTORI_USAGE'
export const RETREIVE_ASSET_HISTORI_USAGE = 'RETREIVE_ASSET_HISTORI_USAGE'
export const DELETE_IMAGE_HISTORI_USAGE = 'DELETE_IMAGE_HISTORI_USAGE'
export const UPDATE_HISTORI_USAGE = 'UPDATE_HISTORI_USAGE'
