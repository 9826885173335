import {
  ADD_ASSET_FORM,
  DELETE_ASSET_FORM,
  RETREIVE_ASSET_FORM,
  UPDATE_ASSET_FORM,
} from 'src/actions/types'
const initialState = []

const AssetFormReducer = (assetform = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ADD_ASSET_FORM:
      console.log(payload)
      return [...assetform, payload]
    case RETREIVE_ASSET_FORM:
      return payload
    case UPDATE_ASSET_FORM:
      return assetform.map((af) => {
        if (af.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...af,
            ...payload,
          }
        } else {
          return af
        }
      })
    // case DELETE_ASSET:
    //   return assets.filter(({ id }) => id !== payload.id)
    case DELETE_ASSET_FORM:
      return assetform.filter(({ id }) => id !== payload.id)
    default:
      return assetform
  }
}

export default AssetFormReducer
