import { CREATE_TRANSACTION, RETREIVE_TRANSACTION } from 'src/actions/types'
const initialState = []

const TransactionReducer = (transaction = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_TRANSACTION:
      console.log(payload)
      return [...transaction, payload]
    case RETREIVE_TRANSACTION:
      return payload
    // case UPDATE_ASSET:
    //   return assets.map((asset) => {
    //     if (asset.id === payload.id) {
    //       // console.log(merk, payload)
    //       return {
    //         ...asset,
    //         ...payload,
    //       }
    //     } else {
    //       return asset
    //     }
    //   })
    // case DELETE_ASSET:
    //   return assets.filter(({ id }) => id !== payload.id)
    // case DELETE_IMAGE_ASSET:
    //   return assets.filter(({ id }) => id !== payload.id)
    default:
      return transaction
  }
}

export default TransactionReducer
