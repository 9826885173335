import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from 'src/actions/types'

const user = JSON.parse(localStorage.getItem('user'))
const initialState =
  user != undefined ? { isLoggedIn: true, user: user } : { isLoggedIn: false, user: null }

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    default:
      return state
  }
}
