import { RETREIVE_AREA } from 'src/actions/types'
const initialState = []

const AreaReducer = (areas = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETREIVE_AREA:
      return payload
    default:
      return areas
  }
}

export default AreaReducer
