import { RETREIVE_BANGUNAN } from 'src/actions/types'
const initialState = []

const BangunanReducer = (bangunan = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETREIVE_BANGUNAN:
      return payload
    default:
      return bangunan
  }
}

export default BangunanReducer
