import { CREATE_TYPE, UPDATE_TYPE, DELETE_TYPE, RETREIVE_TYPE } from 'src/actions/types'
const initialState = []

const TypeReducer = (types = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATE_TYPE:
      return [...types, payload]
    case RETREIVE_TYPE:
      return payload
    case UPDATE_TYPE:
      return types.map((type) => {
        if (type.id === payload.id) {
          // console.log(merk, payload)
          return {
            ...type,
            ...payload,
          }
        } else {
          return type
        }
      })
    case DELETE_TYPE:
      return types.filter(({ id }) => id !== payload.id)
    default:
      return types
  }
}

export default TypeReducer
