import { RETREIVE_ASSET_CATEGORY } from 'src/actions/types'
const initialState = []

const AssetCategoryReducer = (assetCagegory = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    // case CREATE_ASSET:
    //   console.log(payload)
    //   return [...assets, payload]
    case RETREIVE_ASSET_CATEGORY:
      return payload
    //   case UPDATE_ASSET:
    //     return assets.map((asset) => {
    //       if (asset.id === payload.id) {
    //         // console.log(merk, payload)
    //         return {
    //           ...asset,
    //           ...payload,
    //         }
    //       } else {
    //         return asset
    //       }
    //     })
    //   case DELETE_ASSET:
    //     return assets.filter(({ id }) => id !== payload.id)
    //   case DELETE_IMAGE_ASSET:
    //     return assets.filter(({ id }) => id !== payload.id)
    default:
      return assetCagegory
  }
}

export default AssetCategoryReducer
